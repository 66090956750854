// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-algemene-voorwaarden-jsx": () => import("./../../../src/pages/algemene-voorwaarden.jsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-jsx" */),
  "component---src-pages-app-deel-jsx": () => import("./../../../src/pages/app/deel.jsx" /* webpackChunkName: "component---src-pages-app-deel-jsx" */),
  "component---src-pages-app-manage-jsx": () => import("./../../../src/pages/app/manage.jsx" /* webpackChunkName: "component---src-pages-app-manage-jsx" */),
  "component---src-pages-app-plan-jsx": () => import("./../../../src/pages/app/plan.jsx" /* webpackChunkName: "component---src-pages-app-plan-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookieverklaring-jsx": () => import("./../../../src/pages/cookieverklaring.jsx" /* webpackChunkName: "component---src-pages-cookieverklaring-jsx" */),
  "component---src-pages-disclaimer-jsx": () => import("./../../../src/pages/disclaimer.jsx" /* webpackChunkName: "component---src-pages-disclaimer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-over-ons-jsx": () => import("./../../../src/pages/over-ons.jsx" /* webpackChunkName: "component---src-pages-over-ons-jsx" */),
  "component---src-pages-prijzen-jsx": () => import("./../../../src/pages/prijzen.jsx" /* webpackChunkName: "component---src-pages-prijzen-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-voor-wie-bouwbedrijf-jsx": () => import("./../../../src/pages/voor-wie/bouwbedrijf.jsx" /* webpackChunkName: "component---src-pages-voor-wie-bouwbedrijf-jsx" */),
  "component---src-pages-voor-wie-houtbouw-jsx": () => import("./../../../src/pages/voor-wie/houtbouw.jsx" /* webpackChunkName: "component---src-pages-voor-wie-houtbouw-jsx" */),
  "component---src-pages-voor-wie-jsx": () => import("./../../../src/pages/voor-wie.jsx" /* webpackChunkName: "component---src-pages-voor-wie-jsx" */),
  "component---src-pages-voor-wie-projectontwikkelaar-jsx": () => import("./../../../src/pages/voor-wie/projectontwikkelaar.jsx" /* webpackChunkName: "component---src-pages-voor-wie-projectontwikkelaar-jsx" */),
  "component---src-pages-voor-wie-renovatiebedrijf-jsx": () => import("./../../../src/pages/voor-wie/renovatiebedrijf.jsx" /* webpackChunkName: "component---src-pages-voor-wie-renovatiebedrijf-jsx" */),
  "component---src-pages-wkb-jsx": () => import("./../../../src/pages/wkb.jsx" /* webpackChunkName: "component---src-pages-wkb-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */)
}

